var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Sort",
                    "label-cols-sm": "3",
                    "label-align-sm": "right",
                    "label-size": "sm",
                    "label-for": "sortBySelect",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { size: "sm" } },
                    [
                      _c("b-form-select", {
                        staticClass: "w-75",
                        attrs: { id: "sortBySelect", options: _vm.sortOptions },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function () {
                              return [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("-- none --"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.sortBy,
                          callback: function ($$v) {
                            _vm.sortBy = $$v
                          },
                          expression: "sortBy",
                        },
                      }),
                      _c(
                        "b-form-select",
                        {
                          staticClass: "w-25",
                          attrs: { size: "sm", disabled: !_vm.sortBy },
                          model: {
                            value: _vm.sortDesc,
                            callback: function ($$v) {
                              _vm.sortDesc = $$v
                            },
                            expression: "sortDesc",
                          },
                        },
                        [
                          _c("option", { domProps: { value: false } }, [
                            _vm._v("Asc"),
                          ]),
                          _c("option", { domProps: { value: true } }, [
                            _vm._v("Desc"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Initial sort",
                    "label-cols-sm": "3",
                    "label-align-sm": "right",
                    "label-size": "sm",
                    "label-for": "initialSortSelect",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "initialSortSelect",
                      size: "sm",
                      options: ["asc", "desc", "last"],
                    },
                    model: {
                      value: _vm.sortDirection,
                      callback: function ($$v) {
                        _vm.sortDirection = $$v
                      },
                      expression: "sortDirection",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Filter",
                    "label-cols-sm": "3",
                    "label-align-sm": "right",
                    "label-size": "sm",
                    "label-for": "filterInput",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { size: "sm" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "search",
                          id: "filterInput",
                          placeholder: "Type to Search",
                        },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { disabled: !_vm.filter },
                              on: {
                                click: function ($event) {
                                  _vm.filter = ""
                                },
                              },
                            },
                            [_vm._v("Clear")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Filter On",
                    "label-cols-sm": "3",
                    "label-align-sm": "right",
                    "label-size": "sm",
                    description: "Leave all unchecked to filter on all data",
                  },
                },
                [
                  _c(
                    "b-form-checkbox-group",
                    {
                      staticClass: "mt-1",
                      model: {
                        value: _vm.filterOn,
                        callback: function ($$v) {
                          _vm.filterOn = $$v
                        },
                        expression: "filterOn",
                      },
                    },
                    [
                      _c("b-form-checkbox", { attrs: { value: "name" } }, [
                        _vm._v("Name"),
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "age" } }, [
                        _vm._v("Age"),
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "isActive" } }, [
                        _vm._v("Active"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { sm: "5", md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Per page",
                    "label-cols-sm": "6",
                    "label-cols-md": "4",
                    "label-cols-lg": "3",
                    "label-align-sm": "right",
                    "label-size": "sm",
                    "label-for": "perPageSelect",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "perPageSelect",
                      size: "sm",
                      options: _vm.pageOptions,
                    },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { sm: "7", md: "6" } },
            [
              _c("b-pagination", {
                staticClass: "my-0",
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  align: "fill",
                  size: "sm",
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("CardListBuilder", {
        attrs: {
          "custom-class": "hide-on-desktop",
          items: _vm.items,
          fields: _vm.fields,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function (row) {
              return [
                _c("b-card", [
                  _c(
                    "ul",
                    _vm._l(row.item, function (value, key) {
                      return _c("li", { key: key }, [
                        _vm._v(_vm._s(key) + ": " + _vm._s(value)),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            },
          },
          {
            key: "actions",
            fn: function (row) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-1",
                    attrs: { size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.info(row.item, row.index, $event.target)
                      },
                    },
                  },
                  [_vm._v(" Info modal ")]
                ),
                _c(
                  "b-button",
                  { attrs: { size: "sm" }, on: { click: row.toggleDetails } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(row.detailsShowing ? "Hide" : "Show") +
                        " Details "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("b-table", {
        staticClass: "hide-on-mobile",
        attrs: {
          "show-empty": "",
          small: "",
          stacked: "md",
          items: _vm.items,
          fields: _vm.fields,
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          filter: _vm.filter,
          filterIncludedFields: _vm.filterOn,
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc,
          "sort-direction": _vm.sortDirection,
        },
        on: {
          "update:sortBy": function ($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function ($event) {
            _vm.sortBy = $event
          },
          "update:sortDesc": function ($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function ($event) {
            _vm.sortDesc = $event
          },
          filtered: _vm.onFiltered,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(name)",
            fn: function (row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(row.value.first) +
                    " " +
                    _vm._s(row.value.last) +
                    " "
                ),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (row) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-1",
                    attrs: { size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.info(row.item, row.index, $event.target)
                      },
                    },
                  },
                  [_vm._v(" Info modal ")]
                ),
                _c(
                  "b-button",
                  { attrs: { size: "sm" }, on: { click: row.toggleDetails } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(row.detailsShowing ? "Hide" : "Show") +
                        " Details "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "row-details",
            fn: function (row) {
              return [
                _c("b-card", [
                  _c(
                    "ul",
                    _vm._l(row.item, function (value, key) {
                      return _c("li", { key: key }, [
                        _vm._v(_vm._s(key) + ": " + _vm._s(value)),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.infoModal.id,
            title: _vm.infoModal.title,
            "ok-only": "",
            "modal-class": "mui-animation",
            fade: false,
          },
          on: { hide: _vm.resetInfoModal },
        },
        [_c("pre", [_vm._v(_vm._s(_vm.infoModal.content))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }